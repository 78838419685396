/** 
 * Typography for GovTeams. 
 * Defines base styles for all typographic elements including headings, body text, buttons, line separators etc
 */

html {
    .govTeams-fabric {
        @include ms-Fabric;
    }

    @media (max-width: $ms-screen-max-md) {
        display: flex;
        margin: 0;
    }

    h1,
    .h1 {
        // @include ms-font-xxl;
        font-size: 36px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 20px;

        @media (max-width: $ms-screen-min-lg) {
            font-size: 24px;
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 10px;
        }
    }

    h2,
    .h2 {
        @include ms-fontWeight-semibold;
        font-size: 26px;
        color: $govTeams-blueDark;
        margin-top: 0;
        margin-bottom: 20px;
    }

    h3,
    .h3 {
        @include ms-font-xl;
        @include ms-fontWeight-semibold;
    }

    h4,
    .h4 {
        @include ms-fontWeight-semibold;
        font-size: 18px;
    }

    .heading-meta {
        @include ms-fontWeight-light;
    }

    a,
    button,
    .ms-Link {
        @include ms-fontWeight-semibold;
    }

    a.tag {
        @include ms-fontWeight-regular;
        font-size: inherit;
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    .heading-tooltip {
        display: inline-block;

        .heading-tooltip-icon {
            @include ms-fontSize-m;
            color: $ms-color-themePrimary;
            margin-left: 10px;
        }
    }

    label {
        @include ms-fontWeight-regular;
    }

    hr {
        border-top: 1px solid $ms-color-neutralLight;
    }

    .ms-TooltipHost {
        cursor: pointer;
    }

    .ms-DetailsHeader-cellTitle {
        @include ms-fontWeight-semibold;
    }
}

    .govTeams-App-wrapper {
        .ms-DetailsList {
            font-size: 14px;

            .ms-DetailsRow-cell {
                @include ms-fontColor-neutralDark;
                // white-space: normal !important;
            }
        }
    }
    // .govTeams-pageCommunity,
    // .govTeams-pageInviteMembers,
    // .govTeams-pageProfile,
    // .govTeams-pageReactivate {
    //   h1 {
    //     @include ms-fontWeight-semilight;
    //     font-size: 26px;
    //   }
    //   h2,
    //   .h2 {
    //     @include ms-font-xl;
    //     @include ms-fontWeight-regular;
    //   }
    //   h3,
    //   .h3 {
    //     @include ms-font-l;
    //     @include ms-fontWeight-semibold;
    //   }
    // }
